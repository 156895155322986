<template>
	<div class="error-wrap">
		<!-- <div class="error-pic">
			<img src="@/assets/img/404-1.png" alt="404" class="pic-404__parent">
			<img src="@/assets/img/404-2.png" alt="404" class="pic-404__child left">
			<img src="@/assets/img/404-2.png" alt="404" class="pic-404__child mid">
			<img src="@/assets/img/404-2.png" alt="404" class="pic-404__child right">
		</div> -->
		<div class="bullshit">
			<!-- <a :href="$store.state.ROOT_URL">消毒设备网</a> -->
			<div class="bullshit__oops">哎呀!</div>
			<div class="bullshit__info">权限不足~暂时不能进入这个页面</div>
			<div class="bullshit__headline">请升级<a :href="$store.state.ROOT_URL+'/vip'">高级会员</a> ...</div>
			<div class="bullshit__return-home" @click="handleGj">立即升级</div>
		</div>
	</div>
</template>

<script>
	export default {
		methods:{
			handleGj() {
				let params={
					project:"高级会员"
				}
				this.$axios.post('/applyService',params).then(data => {
					if (data.code == 41004) {
						this.$message.error(data.msg)
						return
					}else{
						this.$alert('申请成功，我们的客户经理将于1个工作日内与您联系！', '高级会员', {
							type: 'success'
						})
					}
				})
			},
		}
	}
</script>

<style scoped>
	.error-wrap {
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: flex;
	}

	.error-pic {
		width: 600px;
		position: relative;
	}

	.pic-404__parent {
		width: 100%;
	}

	.pic-404__child {
		position: absolute;
	}

	.pic-404__child.left {
		width: 80px;
		top: 17px;
		left: 220px;
		opacity: 0;
		animation-name: cloudLeft;
		animation-duration: 2s;
		animation-timing-function: linear;
		animation-fill-mode: forwards;
		animation-delay: 1s
	}

	.pic-404__child.mid {
		width: 46px;
		top: 10px;
		left: 420px;
		opacity: 0;
		animation-name: cloudMid;
		animation-duration: 2s;
		animation-timing-function: linear;
		animation-fill-mode: forwards;
		animation-delay: 1.2s
	}

	.pic-404__child.right {
		width: 62px;
		top: 100px;
		left: 500px;
		opacity: 0;
		animation-name: cloudRight;
		animation-duration: 2s;
		animation-timing-function: linear;
		animation-fill-mode: forwards;
		animation-delay: 1s
	}


	@keyframes cloudLeft {
		0% {
			top: 17px;
			left: 220px;
			opacity: 0
		}

		20% {
			top: 33px;
			left: 188px;
			opacity: 1
		}

		80% {
			top: 81px;
			left: 92px;
			opacity: 1
		}

		to {
			top: 97px;
			left: 60px;
			opacity: 0
		}
	}

	@keyframes cloudMid {
		0% {
			top: 10px;
			left: 420px;
			opacity: 0
		}

		20% {
			top: 40px;
			left: 360px;
			opacity: 1
		}

		70% {
			top: 130px;
			left: 180px;
			opacity: 1
		}

		to {
			top: 160px;
			left: 120px;
			opacity: 0
		}
	}

	@keyframes cloudRight {
		0% {
			top: 100px;
			left: 500px;
			opacity: 0
		}

		20% {
			top: 120px;
			left: 460px;
			opacity: 1
		}

		80% {
			top: 180px;
			left: 340px;
			opacity: 1
		}

		to {
			top: 200px;
			left: 300px;
			opacity: 0
		}
	}

	.bullshit {
		text-align: center;
		position: relative;
		width: 300px;
		padding: 30px 0;
		overflow: hidden
	}

	.bullshit__oops {
		font-size: 42px;
		line-height: 40px;
		color: #1482f0;
		margin-bottom: 20px;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}

	.bullshit__headline,
	.bullshit__oops {
		font-weight: 700;
		opacity: 0;
		animation-name: slideUp-data;
		animation-duration: .5s
	}

	.bullshit__headline {
		font-size: 30px;
		line-height: 24px;
		padding-bottom: 30px;
		color: #222;
		margin-bottom: 10px;
		animation-delay: .1s;
		animation-fill-mode: forwards
	}

	.bullshit__info {
		font-size: 13px;
		line-height: 21px;
		color: grey;
		margin-bottom: 30px;
		animation-delay: .2s;
		animation-fill-mode: forwards
	}
	

	.bullshit__info,
	.bullshit__return-home {
		opacity: 0;
		animation-name: slideUp-data;
		animation-duration: .5s
	}

	.bullshit__return-home {
		display: block;
		/* float: left; */
		margin: 0 auto;
		width: 110px;
		height: 36px;
		background: #1482f0;
		border-radius: 100px;
		text-align: center;
		color: #fff;
		font-size: 14px;
		line-height: 36px;
		cursor: pointer;
		animation-delay: .3s;
		animation-fill-mode: forwards
	}

	@keyframes slideUp-data {
		0% {
			transform: translateY(60px);
			opacity: 0
		}

		to {
			transform: translateY(0);
			opacity: 1
		}
	}
</style>
